@import "~csp-common-ui/dist/styles/color.scss";
@import "~csp-common-ui/dist/styles/media-query.scss";

.TermPageContainer{
    
    padding: 60px;
    @include xs(){
        padding: 10px 0px 10px 10px;
    }

    img {
        width: 250px;
        @include xs() {
            width: 140px;
        }
    }
    .heading{
        @include xs(){
            line-height: 20px;
            font-size: 18px;
        }
        color: $primaryDark;
        text-align: center;
        font-size: 56px;
        line-height: 80px;
    }
}
